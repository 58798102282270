<template>
    <div class="company-contain">
        <div class="head-box">
            <div class="title">任务列表</div>
            <el-button size="small" @click="publishTask">发布任务</el-button>
        </div>
        <div class="divide">
            <el-divider></el-divider>
        </div>
        <!--筛选条件-->
        <div class="filter-box">
            <div class="box1">
                <p>任务分类：</p>
                <span @click="allClas" :class="{activeClass:isAll == 0}">全部</span>
                <span @click="isAllS(item.id)"
                      :class="{activeClass:isAll == item.id}"
                      v-for="item in typeLsit"
                      :key="item.id"
                >{{item.title}}</span>
            </div>
            <div class="box1">
                <p>任务筛选：</p>
                <span @click="isFilter(index)"
                      v-for="(item,index) in filterList"
                      :class="{activeClass:isFilt == index}"
                      :key="index"
                >{{item}}</span>
            </div>
            <div class="box1">
                <p>条件筛选：</p>
                <span class="sort-item" :class="{activeClass:isFilt1 === -1}" @click="isFilter1(-1)">全部</span>
                <span @click="isFilter1(item.id)"
                      v-for="(item,index) in filterList1"
                      :class="{activeClass:isFilt1 == item.id}"
                      :key="item.id"
                >{{item.name}}</span>
            </div>
        </div>
        <div class="table-box">
            <el-table
                    :data="taskList"
                    class="college-table"
                    style="width: 100%; flex: 1" height="1%"
                    size="medium"
                    :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
                    :cell-style="{fontSize: '12px',color: '#333'}"
            >
                <el-table-column
                        prop="task_id"
                        label="序号"
                        width="140"
                        align="center"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.task_id}}</span>
                    </template>
                </el-table-column>

                <el-table-column width="260" prop="task_title" label="任务标题" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <div class="showing">
                            <img class="imgBox" :src=scope.row.task_cover>
                            <span class="showing-name">{{scope.row.task_title}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="任务金额" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">¥{{scope.row.task_money}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="报名状态" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title" v-if="scope.row.task_is_apply == 0">无需报名</span>
                        <span class="student-name-title" v-if="scope.row.task_is_apply == 1">需报名</span>
                        <span class="student-name-title" v-if="scope.row.task_is_apply == 3">合作咨询</span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="参与人数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title" v-if="scope.row.num==0">无限制</span>
                        <span class="student-name-title" v-else>{{scope.row.num}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="task_state"
                        label="任务状态"
                        align="center"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.task_state}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="发布时间"
                        align="center"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.create_time}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.task_is_apply !== 0" size="small"
                                   @click="changeToRegist(scope.row)">报名列表
                        </el-button>
                        <el-button type="text" size="small" @click="changeToWorks(scope.row)">查看作品</el-button>
                        <el-button type="text" v-if="scope.row.task_state != '已截止'" size="small"
                                   @click="editCompany(scope.row)">编辑
                        </el-button>
                        <el-button type="text" size="small" @click="delCompany(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    style="padding: 10px;"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'taskPublish',
        data() {
            return {
                isAll: 0,
                typeLsit: [], //分类列表
                isFilt: 0,
                isFilt1: -1,
                task_condition: "",
                filterList: ["全部", "进行中", "已截止"],
                filterList1: [
                    {
                        id: 0,
                        name: '无需报名'
                    }, {
                        id: 1,
                        name: '需报名'
                    }, {
                        id: 3,
                        name: '合作咨询'
                    }
                ],
                taskList: [],//任务列表
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //编辑获得的数据
                editForm: {
                    id: '',
                    tasktype: "",//任务分类
                    title: "", //标题
                    describe: "", //描述
                    cover: "", //封面
                    time: "", //截至时间
                    Islimit: "",//是否限制人数 0否 1是
                    limitnum: "",//限制人数
                    taskmoney: "",//任务金额
                    apply: "",//是否报名 0否 1是
                    file: [] //附件
                }
            }
        },
        mounted() {
            this.getTaskType();
            this.getAllClass();
        },
        methods: {
            isAllS(id) {
                this.isAll = id;
            },
            isFilter(id) {
                this.isFilt = id;
            },
            isFilter1(id) {
                this.isFilt1 = id;
                if (this.isFilt1 == -1) {
                    this.task_condition = ''
                } else {
                    this.task_condition = id
                }
            },
            //全部
            allClas() {
                this.isAll = 0;
            },
            getTaskType() {
                let params = {};
                this.$httpcompany.axiosGetBy(this.$api.taskTypeList, params, res => {
                    this.typeLsit = res;
                });
            },
            // 获取全部任务列表
            getAllClass() {
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum, //页面显示条数
                    type_id: this.isAll,//任务类型
                    task_state: this.isFilt,//任务状态 1 进行中 2 已截止
                    task_condition: this.task_condition//任务条件是否报名
                };
                if (this.isAll == 0) {
                    delete param.type_id
                }
                if (this.isFilt == 0) {
                    delete param.task_state
                }
                if (this.isFilt1 == -1) {
                    delete param.task_condition
                }
                this.$httpcompany.axiosGetBy(this.$api.taskList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.data.list;
                        this.adminPages.total = res.data.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        })
                    }
                });
            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAllClass();
            },
            //查看报名列表
            changeToRegist(row) {
                this.$router.push(
                    {
                        path: "/company/taskcenter/registList",
                        query: {task_id: row.task_id, apply: row.task_is_apply}
                    })
            },
            changeToWorks(row) {
                this.$router.push(
                    {
                        path: "/company/taskcenter/worksList",
                        query: {task_id: row.task_id}
                    })
            },
            // 编辑
            editCompany(row) {
                this.$router.push({
                    path: "/company/taskcenter/publish",
                    query: {
                        task_id: row.task_id
                    }
                })
            },
            // 删除
            delCompany(row) {
                let that = this;
                const id = row.task_id;
                if (row.task_state == "进行中") {
                    this.$message({
                        message: '任务进行中无法删除',
                        type: 'warning'
                    });
                    return
                } else {
                    let param = {
                        task_id: id
                    };
                    this.$confirm("删除系统任务后不能恢复，确定要删除吗？", "删除任务", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        that.$httpcompany.axiosGetBy(that.$api.delTask, param, res => {
                            console.log(res);
                            if (res.code == 200) {
                                that.$message({
                                    type: "success",
                                    message: "删除成功"
                                });
                                that.getAllClass();
                            } else {
                                that.$message.warning(res.msg);
                            }
                        });
                    });
                }
            },
            //发布任务
            publishTask() {
                this.$router.push({path: "/company/taskcenter/publish"})
            }
        },
        watch: {
            isAll: function (val) {
                this.getAllClass();
            },
            isFilt: function (val) {
                this.getAllClass();
            },
            isFilt1: function (val) {
                this.getAllClass();
            }
        }

    }
</script>

<style scoped lang="scss">
    .activeClass {
        background: #4d5aff;
        color: #ffffff;
    }

    .company-contain {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);

        .head-box {
            display: flex;
            justify-content: space-between;
            height: 40px;
            padding: 10px 30px 0 20px;

            .title {
                margin-top: 10px;
            }

            ::v-deep .el-button {
                background: #4D5AFF;
                border-radius: 2px;
                color: #ffffff;
                text-align: center;
            }

        }

        .divide {
            padding: 0 20px;
        }

        .filter-box {
            .box1 {
                padding: 0 20px 10px 20px;
                height: 40px;
                display: flex;

                span {
                    margin-top: 8px;
                    line-height: 32px;
                    display: inline-block;
                    width: 80px;
                    height: 32px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .table-box {
            display: flex;
            flex-direction: column;
            height: 1%;
            flex: 1;

            .showing {
                display: flex;
                align-items: center;
                /*width: 30px;*/
                height: 30px;
                margin-left: 50px;

                img {
                    display: inline-block;
                    width: 57px;
                    height: 33px;
                    margin-right: 10px;
                }

                .showing-name {
                    max-width: 260px;
                    line-height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
        }
    }
</style>
